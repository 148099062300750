import request from '../../utils/request';

/**
 * 创建比赛
 */
export const createPKGame = (item) => {
    return request({
        url: '/sys/PKGame/insertPKGame',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(item),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
/**
 * 创建比赛
 */
export const PKGameStatistics = (item) => {
    return request({
        url: '/sys/PKGame/PKGameStatistics',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(item),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
/**
 * 编辑比赛
 */
export const updatePKGame = (item) => {
    return request({
        url: '/sys/PKGame/updatePKGame',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(item),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
/**
 * 编辑比赛状态
 */
export function updatePKGameState(id) {
    return request({
        url: '/sys/PKGame/updatePKGameState',
        method: 'post',
        data: JSON.stringify({id}),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    })
}
/**
 * 删除参赛队伍
 */
export function deletePKGroup(id) {
    return request({
        url: '/sys/PKGame/deletePKGroup',
        method: 'post',
        data: JSON.stringify({id}),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    })
}

/**
 * 删除比赛
 */
export function deletePKGame(id) {
    return request({
        url: '/sys/PKGame/deletePKGame',
        method: 'post',
        data: JSON.stringify({id}),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    })
}
/**
 * 查询PK比赛
 */
export const selectPKGame = (query) => {
    return request({
        url: '/sys/PKGame/selectPKGame',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

/**
 * 根据比赛队（list） 查询每队的成员
 */
export const selectMemberByGroup = (list) => {
    return request({
        url: '/sys/PKGame/selectMemberByGroup',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
