import request from '../utils/request';

/**
 * 获取学院培训
 * @param query
 * @returns {AxiosPromise}
 */
export const getRegion = (query) => {
    return request({
        url: '/sys/region/page', 
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

//修改城市状态
export const updateRegionState = (id, state) => {
    return request({
        url: '/sys/region/updateRegionState',
        method: 'POST',
        params: {id, state}
    });
};
//新增
export const insertRegion = (region) => {
    return request({
        url: '/sys/region/insertRegion',
        method: 'POST',
        params: region
    });
};
//修改城市状态
export const updateRegion = (region) => {
    return request({
        url: '/sys/region/updateRegion',
        method: 'POST',
        data: region
    });
};
//删除
export const deleteRegion = (id) => {
    return request({
        url: '/sys/region/deleteRegion',
        method: 'GET',
        params: {id}
    });
};
//查询城市下的账户信息
export const selectRegionAccount = (name) => {
    return request({
        url: '/sys/region/selectRegionAccount',
        method: 'GET',
        params: {name}
    });
};