<template>
  <div style="padding: 20px">
    <el-input placeholder="请输入比赛名称" v-model="query.name" style="width: 150px;margin-right: 10px"></el-input>
    <el-button style="margin-right: 10px" type="primary" @click="openCreatePKGameDialog">新增</el-button>
    <el-button style="margin-right: 10px" type="primary" @click="selectPKGame">搜索</el-button>
    <el-button style="margin-right: 10px" type="primary" @click="PKGameStatistics">发送群通知</el-button>
    <create-game ref="createGame" @selectTable="selectPKGame"/>
    <update-game ref="updateGame" @selectTable="selectPKGame"/>
    <el-table v-loading="tableLogin" :data="tableData" border style="width: 100%;margin-top: 50px;height: 580px">
      <el-table-column prop="id" label="ID" width="100"/>
      <el-table-column prop="name" label="PK标题" show-overflow-tooltip/>
      <el-table-column prop="address" label="PK时间" width="320" >
        <template #default="scope">
          <span>{{ scope.row.startTime }} ~ {{ scope.row.endTime }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="lowTarget" label="基础目标" width="150" show-overflow-tooltip/>
      <el-table-column prop="tallTarget" label="冲刺目标" width="150" show-overflow-tooltip/>
      <el-table-column prop="address" label="参赛队伍"  width="180" >
        <template #default="scope">
          <el-button @click="selectMemberByGroup(scope.row)" type="primary" link>{{ scope.row.group.length }} 队</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="180"/>
      <el-table-column label="状态" width="110">
        <template #default="scope">
          <el-tooltip :content="scope.row.state=='开启'? '确定要禁用这场PK吗？':'确定要开启这场PK吗？'" placement="top">
            <el-switch
                @click="updatePKGameState(scope.row.id)"
                v-model="scope.row.state"
                active-value="开启"
                inactive-value="禁用"
            >
            </el-switch>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="110" align="center">
        <template #default="scope">
          <el-button style="margin: 0 6px 0 0;padding: 0" size="small" type="primary" text @click="openUpdatePKGameDialog(scope.row)">编辑</el-button>
          <el-popconfirm
              :width="200"
              @confirm="deletePKGame(scope.row.id)"
              title="确定删除本场比赛吗？"
              confirm-button-text="确定"
              cancel-button-text="取消">
            <template #reference>
              <el-button style="margin: 0 0 0 6px;padding: 0" size="small" type="danger" text>删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="query.pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <el-dialog v-model="groupDialog" title="参赛队伍" width="1300" draggable style="margin-top: 100px">
        <div style="display: flex;justify-content: space-around;flex-wrap: wrap;height: 600px;overflow: auto;">
          <div v-for="(item,index) in tableItem.group" :key="index">
              <div class="group-item" :style="index>2 ? 'margin-top: 50px;':'margin-top: 10px;'">
                <div style="text-align: center;">
                  <div v-if="item.picture!=null" style="position: relative">
                    <el-image class="picture" :preview-src-list="[item.picture]" :src="item.picture" fit="cover"/>
                  </div>
                  <div style="font-weight: 900;font-size: 15px;margin-top: 8px;color: black">{{item.name}}</div>
                </div>
                  <div style="margin-top: 14px;display: flex;justify-content: space-between">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        placement="top-start">
                      <template #content>
                        目标：{{item.lowTarget}}
                        <br />
                        已完成：{{item.totalMoney}} - 占比：{{item.lowTargetCompletePercent}}%
                      </template>
                      <div style="width: 48%;">目标：{{item.lowTarget}}
                        <el-progress :text-inside="true" :stroke-width="14" :percentage="item.lowTargetCompletePercent" />
                      </div>
                    </el-tooltip>
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        placement="top-start">
                      <template #content>
                        冲刺：{{item.tallTarget}}
                        <br />
                        已完成：{{item.totalMoney}} - 占比：{{item.tallTargetCompletePercent}}%
                      </template>
                      <div style="width: 48%;">冲刺：{{item.tallTarget}}
                        <el-progress :text-inside="true" :stroke-width="14" :percentage="item.tallTargetCompletePercent" />
                      </div>
                    </el-tooltip>

                  </div>
                <div class="el-tree-father">
                  <el-tree
                      v-if="item.groupMember!=null && item.groupMember.length>0"
                      :data="item.groupMember" draggable :allow-drop="true"
                      :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}">
                  </el-tree>
                  <div v-else >
                    <el-empty
                        imageSize="100px" description="无成员信息！"/>
                  </div>
                </div>
              </div>
          </div>
          <div class="placeholder"></div>
          <div class="placeholder"></div>
        </div>
    </el-dialog>
  </div>
</template>

<script>

import createGame from "./components/CreateGame";
import {selectPKGame,selectMemberByGroup,updatePKGameState,deletePKGame,PKGameStatistics} from "@/api/pkGame/pkGame";
import UpdateGame from "./components/UpdateGame";
export default {
  name: "PKGame",
  components:{
    UpdateGame,
    createGame
  },
  data() {
    return {
      tableData:[],
      tableLogin:false,
      tableItem:null,
      groupDialog:false,
      query:{
        name:"",
        pageIndex:1,
        pageSize:11,
        pageTotal:0,
      },
      PKGamequery:{
          deptId:34,
          campusId:null,
          coachId:null,
          regionId:null
      }
    }
  },
  methods:{
    /**
     * 打开创建PK的弹出层
     */
    openCreatePKGameDialog(){
      this.$refs.createGame.openDialog();
    },
    /**
     * 打开创建PK的弹出层
     */
    openUpdatePKGameDialog(item){
      this.$refs.updateGame.openDialog(item);
    },
    updatePKGameState(id){
      console.log(id)
      updatePKGameState(id).then(value => {
        console.log(value)
      })
    },
    PKGameStatistics(){
      PKGameStatistics(this.PKGamequery).then(value => {
        console.log(value)
      })
    },
    selectMemberByGroup(item){
      this.tableItem = JSON.parse(JSON.stringify(item));
      selectMemberByGroup(this.tableItem.group).then(val => {
        if (val.code == 200){
          for (const item of val.data) {
            item.groupMember = this.disposeTree(item.groupMember)
          }
          this.tableItem.group = val.data;
          this.groupDialog = true;
        }else {
          this.$message.error("队成员获取失败！");
        }
      })
    },
    deletePKGame(id){
      deletePKGame(id).then(val => {
        if (val.code == 200) {
          this.selectPKGame();
          this.$message.success('删除成功！');
        }else {
          this.$message.error("删除失败");
        }
      })
    },
    /**
     * 树结构的children会存在[]、null 统一处理为 undefined
     */
    disposeTree(tree) {
      if (tree!=null){
        tree.forEach(items => {
          if (items!=null){
            if (items.memberId!=null){
              items.id = items.memberId;
              items.name = items.memberName;
            }
            if (items.children != null && items.children.length > 0) {
              this.disposeTree(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return tree;
      }
    },

    handlePageChange(index) {
      this.query.pageIndex = index;
      this.selectPKGame();
    },
    selectPKGame(){
      this.tableLogin = true;
      selectPKGame(this.query).then(val => {
        this.tableLogin = false;
        if (val.code == 200) {
          this.tableData = val.data.records;
          this.query.pageTotal = val.data.total;
        } else {
          this.$message.error("获取表格数据失败");
        }
      })
    },
  },
  created(){
    this.selectPKGame();

  }
}
</script>

<style  type="less">
/* CJL TEST
1.重写plus的image图片预览大图的的position定位样式为static !important;
2.需在el-table中手动添加要覆盖的class名 如el-table__cell
3.此css代码需放在全局的less的style节点中 */
.el-table__cell {
  position: static !important;
}

</style>
<style scoped>
.group-item{
  width: 350px;
  padding: 20px 10px;
  height: 460px;
  box-shadow: 0px 1px 10px 1px #f5f5f5;
  border-radius: 20px;

}
.picture{
  width: 46px;
  height: 46px;
  box-shadow: 3px 3px 20px rgba(94, 94, 94, 0.2);
  border-radius: 12px;
  padding: 2px;
  border: none;

}
.placeholder{
  width: 320px;
  padding: 20px;
  margin-bottom: 30px;
}
.el-tree-father{
  height: 280px;
  overflow: auto;
  margin-top: 10px;
}
</style>